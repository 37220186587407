export enum ElementTypeEnum {
  LINE = 'line',
  LWPOLYLINE = 'lwpolyline',
  CIRCLE = 'circle',
  ARC = 'arc',
  TEXT = 'text',
  INSERT = 'insert',
  MTEXT = 'mtext',
  SPLINE = 'spline',
  ELLIPSE = 'ellipse',
  SOLID = 'solid',
  ATTDEF = 'attdef',
  DIMENSION = 'dimension',
  POINT = 'point'
}

export enum LayersEnum {
  TOTAL_STATION = 'total_station_ref_axis',
  PAINT = 'paint',
  PAINT_OLD = 'paint_old',
  OBSTACLES = 'obstacles',
  OBSTACLES_EDIT = 'obstacles_edit',
  DISCARDED = 'discarded'
}

export enum ToolsEnum {
  ruler = 'ruler'
}

export enum UnitsValuesNamesEnum {
  inches = 'inches',
  feet = 'feet',
  milimeters = 'milimeters',
  centimeters = 'centimeters',
  meters = 'meters',
  decimeters = 'decimeters',
  surveyFeet = 'surveyFeet'
}

/** Units values */
export enum UnitsValuesEnum {
  inches = 1,
  feet = 2,
  milimeters = 4,
  centimeters = 5,
  meters = 6,
  decimeters = 14,
  surveyFeet = 21
}

export enum UnitsAcronymEnum {
  inches = 'in',
  feet = 'ft',
  milimeters = 'mm',
  centimeters = 'cm',
  meters = 'm',
  decimeters = 'dm',
  surveyFeet = 'uft'
}

export enum LayerElementsEnum {
  ARCS = 'arcs',
  CIRCLES = 'circles',
  POINTS = 'points',
  SEGMENTS = 'segments',
  TEXTS = 'texts'
}
export enum MarkersPanesEnum {
  ROBOT = 'robotMarker',
  TOTAL_STATION = 'totalStationMarker'
}
