import * as _ from 'lodash';

import {
  ArcLayerElement,
  CircleLayerElement,
  CommonProperties,
  CurveCommonProperties,
  DrawProperties,
  EventMap,
  ILayerInfo,
  LayerElement,
  LayerElementDrawingFunctionMapper,
  LayerProperties,
  PointLayerElement,
  PointObject,
  TextLayerElement
} from '../definitions';
import {
  Circle,
  CircleMarker,
  FeatureGroup,
  LatLng,
  LatLngBounds,
  Layer,
  Map,
  Polyline,
  featureGroup,
  popup
} from 'leaflet';
import { ElementTypeEnum, LayerElementsEnum, LayersEnum } from '../map.enums';

import { MapConstants } from '../map.constants';
import { MapHelper } from '../map-helper/map-helper.service';
import { KeysToCamelCase } from '../../../utils/definitions';

export interface IMapDrawingService {
  ELEMENT_DRAWING_FUNCTION: LayerElementDrawingFunctionMapper;
  getCurveCommonProperties(
    element: LayerElement,
    type: ElementTypeEnum,
    drawProperties: DrawProperties<CircleLayerElement>,
    center: PointObject,
    radius: number
  ): CurveCommonProperties;
  getCommonProperties(
    element: LayerElement,
    type: ElementTypeEnum,
    drawProperties: DrawProperties<LayerElement>
  ): CommonProperties;
  drawMap(
    map: Map,
    data: Record<string, ILayerInfo>,
    eventMap: EventMap,
    isInteractive: ((layer: string) => boolean) | boolean,
    onDrawCallback: (layer: string, elements: FeatureGroup) => void
  ): void;
  drawTotalStationDecorators(
    element: LayerElement,
    currentLayerProperties: LayerProperties
  ): CircleMarker[];
  drawCircles(drawProperties: DrawProperties<CircleLayerElement>): Circle[];
  drawPoints(drawProperties: DrawProperties<PointLayerElement>): Circle[];
  drawLines(drawProperties: DrawProperties<LayerElement>): Polyline[];
  drawArcs(drawProperties: DrawProperties<ArcLayerElement>): Polyline[];
  drawTexts(drawProperties: DrawProperties<TextLayerElement>): Polyline[];
  openMapCoordsPopup(map: Map, latlng: LatLng);
  getLayerElementsEntries(
    layerData: ILayerInfo
  ): [LayerElementsEnum, LayerElement[]][];
}

export class MapDrawingService implements IMapDrawingService {
  public ELEMENT_DRAWING_FUNCTION: LayerElementDrawingFunctionMapper = {
    [LayerElementsEnum.ARCS]: this.drawArcs.bind(this),
    [LayerElementsEnum.CIRCLES]: this.drawCircles.bind(this),
    [LayerElementsEnum.POINTS]: this.drawPoints.bind(this),
    [LayerElementsEnum.SEGMENTS]: this.drawLines.bind(this),
    [LayerElementsEnum.TEXTS]: this.drawTexts.bind(this)
  };
  private radius = 10;
  protected constants: MapConstants;
  protected mapHelper: MapHelper;
  constructor(constants: MapConstants, mapHelper: MapHelper) {
    this.constants = constants;
    this.mapHelper = mapHelper;
  }

  public drawMap(
    map: Map,
    data: Record<string, ILayerInfo>,
    eventMap: EventMap,
    isInteractive: ((layer: string) => boolean) | boolean,
    onDrawCallback: (layer: string, elements: FeatureGroup) => void
  ) {
    let mapBounds: LatLngBounds;
    map.setView([0, 0]);
    for (const [layer, layerData] of Object.entries(data)) {
      const elements: Layer[] = [];
      if (layerData) {
        const layerDataEntries = this.getLayerElementsEntries(layerData);
        for (const [type, layerElements] of layerDataEntries) {
          const drawedElements = this.ELEMENT_DRAWING_FUNCTION[type]({
            layer,
            elements: layerElements,
            interactive:
              isInteractive instanceof Function
                ? isInteractive(layer)
                : isInteractive,
            eventMap,
            unitConversionFactor: map.options.unitConversionFactor
          });
          elements.push(...drawedElements);
        }
        if (elements.length > 0) {
          const elementsGroup = featureGroup(elements);
          if (layerData.visible) {
            map.addLayer(elementsGroup);
            if (mapBounds == null) {
              mapBounds = elementsGroup.getBounds().pad(0.4);
              map.fitBounds(mapBounds);
            } else {
              mapBounds.extend(elementsGroup.getBounds().pad(0.4));
            }
          }
          onDrawCallback(layer, elementsGroup);
        }
      }
    }
    const minZoom = map.getBoundsZoom(mapBounds);
    map.setMinZoom(minZoom);
    map.fitBounds(mapBounds);
  }

  /**
   * Gets the curves common properties to create the leaflet object
   *
   * @param element Current layer element properties object to get the common properties object
   * @param type Current element type
   * @param drawProperties Current draw properties object
   * @param center {x,y} point representing the center of the arc
   * @param radius radius of the arc
   * @returns The curve common properties object
   */
  public getCurveCommonProperties(
    element: LayerElement,
    type: ElementTypeEnum,
    drawProperties: DrawProperties<CircleLayerElement>,
    center: PointObject,
    radius: number
  ): CurveCommonProperties {
    return {
      ...this.getCommonProperties(element, type, drawProperties),
      center,
      radius
    };
  }

  /**
   * * Gets the common properties to create the leaflet object
   *
   * @param element Current layer element properties object to get the common properties object
   * @param type Current element type
   * @param drawProperties Current draw properties object
   * @returns The common properties object
   */
  public getCommonProperties(
    element: LayerElement,
    type: ElementTypeEnum,
    drawProperties: DrawProperties<LayerElement>
  ): CommonProperties {
    const currentLayerProperties: LayerProperties = this.getLayerProperties(
      drawProperties.layer
    );
    return {
      id: element.id,
      type,
      printed: element.printed ? true : false,
      partiallyPrinted: element.partiallyPrinted ? true : false,
      layer: drawProperties.layer,
      selected: element.selected,
      color: this.getColor(
        element,
        currentLayerProperties,
        drawProperties.layer
      ),
      weight: currentLayerProperties.weight,
      handle: element.handle,
      interactive: drawProperties.interactive
    };
  }

  /**
   * Gets the Total station leaflet decorator
   *
   * @param element Current layer element properties object to draw the decorator
   * @param currentLayerProperties Current layer properties object
   * @returns A circle representing the total station
   */
  public drawTotalStationDecorators(
    element: LayerElement,
    currentLayerProperties: LayerProperties
  ): CircleMarker[] {
    const decoratorStart = this.mapHelper.drawCircleMarker({
      center: element.start,
      radius: this.radius,
      color: element.selected
        ? currentLayerProperties.colorNotSelected
        : 'transparent',
      fillColor: element.selected
        ? currentLayerProperties.color
        : 'transparent',
      opacity: 1,
      fillOpacity: 1,
      interactive: false,
      dashArray: null,
      pattern: '',
      lineweight: 0
    });
    const decoratorEnd = this.mapHelper.drawCircleMarker({
      center: element.end,
      radius: this.radius,
      color: element.selected
        ? currentLayerProperties.colorNotSelected
        : 'transparent',
      fillColor: element.selected
        ? currentLayerProperties.color
        : 'transparent',
      opacity: 1,
      fillOpacity: 1,
      interactive: false,
      dashArray: null,
      pattern: '',
      lineweight: 0
    });
    if (element.selected) {
      decoratorStart.bringToFront();
      decoratorEnd.bringToFront();
    } else {
      decoratorStart.bringToBack();
      decoratorEnd.bringToBack();
    }
    return [decoratorStart, decoratorEnd];
  }

  /**
   * Gets the circles leaflet objects array based on the drawProperties passed by parameter
   *
   * @param drawProperties Current draw properties object to create the leaflet elements
   * @returns Array of circles
   */
  public drawCircles(
    drawProperties: DrawProperties<CircleLayerElement>
  ): Circle[] {
    return drawProperties.elements.map((element: CircleLayerElement) => {
      const circleObj = this.mapHelper.drawCircle({
        ...this.getCurveCommonProperties(
          element,
          ElementTypeEnum.CIRCLE,
          drawProperties,
          element.center,
          element.radius
        ),
        fill: false,
        dashArray: element.pattern ? this.constants.MAP_DASH_SIZE : null,
        pattern: element.pattern,
        lineweight: element.lineweight
      });
      this.addHooks(circleObj as Layer, drawProperties.eventMap);
      return circleObj;
    });
  }

  /**
   * Gets the points leaflet objects array based on the drawProperties passed by parameter
   *
   * @param drawProperties Current draw properties object to create the leaflet elements
   * @returns Array of circles representing points
   */
  public drawPoints(
    drawProperties: DrawProperties<PointLayerElement>
  ): Circle[] {
    return drawProperties.elements.map((element: PointLayerElement) => {
      const pointObj = this.mapHelper.drawCircle({
        ...this.getCurveCommonProperties(
          element,
          ElementTypeEnum.POINT,
          drawProperties,
          element.point ?? element.insert,
          0.04 / drawProperties.unitConversionFactor
        ),
        fill: false,
        dashArray: null,
        pattern: ''
      });
      this.addHooks(pointObj as Layer, drawProperties.eventMap);
      return pointObj;
    });
  }

  /**
   * Gets the lines leaflet objects array based on the drawProperties passed by parameter
   *
   * @param drawProperties Current draw properties object to create the leaflet elements
   * @returns Array of polylines
   */
  public drawLines(drawProperties: DrawProperties<LayerElement>): Polyline[] {
    return drawProperties.elements.map((element: LayerElement) => {
      const lineObj = this.mapHelper.drawLine({
        ...this.getCommonProperties(
          element,
          ElementTypeEnum.LINE,
          drawProperties
        ),
        start: element.start,
        end: element.end,
        bulge: element.bulge,
        startDecorator: null,
        dashArray: element.pattern ? this.constants.MAP_DASH_SIZE : null,
        pattern: element.pattern,
        lineweight: element.lineweight
      }); //
      if (element.selected) {
        lineObj.bringToFront();
      } else {
        lineObj.bringToBack();
      }
      // if TS line, add popup component to show start and end point
      if (drawProperties.layer === LayersEnum.TOTAL_STATION) {
        let startString = '';
        let endString = '';
        startString = 'SHARED_STRING_START_POINT';
        endString = 'SHARED_STRING_END_POINT';
        const startSegment: LatLng = lineObj.getLatLngs()[0] as LatLng;
        const endSegment: LatLng = lineObj.getLatLngs()[0] as LatLng;
        // TODO create a popup component and inject it here
        let content = `<div>${startString}</div>`;
        content += `<div style="padding-left: 10px;">x: <b>${this.mapHelper.truncate(
          startSegment.lng,
          3
        )}</b></div>`;
        content += `<div style="padding-left: 10px;">y: <b>${this.mapHelper.truncate(
          startSegment.lat,
          3
        )}</b></div>`;
        content += `<div style="padding-left: 10px;">z: <b>${this.mapHelper.truncate(
          startSegment.alt,
          3
        )}</b></div>`;
        content += `<hr style="border-top: 1px solid #dddddd;">`;
        content += `<div>${endString}</div>`;
        content += `<div style="padding-left: 10px;">x: <b>${this.mapHelper.truncate(
          endSegment.lng,
          3
        )}</b></div>`;
        content += `<div style="padding-left: 10px;">y: <b>${this.mapHelper.truncate(
          endSegment.lat,
          3
        )}</b></div>`;
        content += `<div style="padding-left: 10px;">z: <b>${this.mapHelper.truncate(
          endSegment.alt,
          3
        )}</b></div>`;
        lineObj.bindPopup(content);
      }
      this.addHooks(lineObj, drawProperties.eventMap);
      return lineObj;
    });
  }

  /**
   * Gets the arcs leaflet objects array based on the drawProperties passed by parameter
   *
   * @param drawProperties Current draw properties object to create the leaflet elements
   * @returns Array of polylines representing arcs
   */
  public drawArcs(drawProperties: DrawProperties<ArcLayerElement>): Polyline[] {
    return drawProperties.elements.map((element: ArcLayerElement) => {
      const elementRes = _.mapKeys(element, (v, k) =>
        _.camelCase(k)
      ) as KeysToCamelCase<ArcLayerElement>;
      const arcObj = this.mapHelper.drawArc({
        ...this.getCurveCommonProperties(
          element,
          ElementTypeEnum.ARC,
          drawProperties,
          element.center,
          element.radius
        ),
        start_angle: elementRes.startAngle,
        end_angle: elementRes.endAngle,
        dashArray: elementRes.pattern ? this.constants.MAP_DASH_SIZE : null,
        pattern: elementRes.pattern,
        lineweight: elementRes.lineweight
      });
      this.addHooks(arcObj, drawProperties.eventMap);
      return arcObj;
    });
  }

  /**
   * Gets the texts leaflet markers array based on the drawProperties passed by parameter
   *
   * @param drawProperties Current draw properties object to create the leaflet elements
   * @returns Array of text markers
   */
  public drawTexts(
    drawProperties: DrawProperties<TextLayerElement>
  ): Polyline[] {
    return drawProperties.elements.map((element: TextLayerElement) => {
      const textObj = this.mapHelper.drawText({
        ...this.getCommonProperties(
          element,
          ElementTypeEnum.TEXT,
          drawProperties
        ),
        text: element.text,
        insert: element.insert,
        rotationAngle: element.angle,
        textSize: element.height,
        keyboard: false,
        dashArray: null,
        pattern: ''
      });
      this.addHooks(textObj, drawProperties.eventMap);
      return textObj;
    });
  }

  /**
   * Creates and opens a popup at specified latlng point
   *
   * @param latlng point to create the popup
   */
  public openMapCoordsPopup(map: Map, latlng: LatLng) {
    let content =
      '<div>x: <b>' + this.mapHelper.truncate(latlng.lng, 3) + '</b></div>';
    content +=
      '<div>y: <b>' + this.mapHelper.truncate(latlng.lat, 3) + '</b></div>';
    const coordsPopup = popup();
    coordsPopup.setLatLng(latlng).setContent(content);
    coordsPopup.openOn(map);
    //Close button is created on the openOn function call
    coordsPopup['_closeButton']?.removeAttribute('href');
  }

  public getLayerElementsEntries(
    layerData: ILayerInfo
  ): [LayerElementsEnum, LayerElement[]][] {
    const initialEntries = Object.entries(layerData);
    return initialEntries.filter(([key]) => {
      return Object.values(LayerElementsEnum).includes(
        key as LayerElementsEnum
      );
    }) as [LayerElementsEnum, LayerElement[]][];
  }

  /**
   * Gets the layer properties of the layer passed by parameter
   *
   * @param layer
   * @returns layer properties object
   */
  public getLayerProperties(layer: string): LayerProperties {
    return (
      this.constants.LAYER_PROPERTIES[layer] ??
      this.constants.LAYER_PROPERTIES.other
    );
  }

  /**
   * Gets the color of the element depending on the current layer properties
   *
   * @param element Current layer element object to get its drawing color
   * @param currentLayerProperties current layer properties object
   * @returns color string
   */
  protected getColor(
    element: LayerElement,
    currentLayerProperties: LayerProperties,
    layer: string
  ): string {
    if (layer === LayersEnum.TOTAL_STATION && !element.selected) {
      return 'transparent';
    } else {
      if (element.selected) {
        return currentLayerProperties.color;
      }
      if (element.printed) {
        return this.constants.MAP_PRINTED_COLOR;
      }
      if (element.partiallyPrinted) {
        return this.constants.MAP_PARTIALLY_PRINTED_COLOR;
      }
      return (
        currentLayerProperties.colorNotSelected ?? currentLayerProperties.color
      );
    }
  }

  protected addHooks(element: Layer, eventMap: EventMap) {
    for (const [event, handler] of Object.entries(eventMap)) {
      element.on(event, handler);
    }
  }
}
