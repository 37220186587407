/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
//Disabled underscore dangle warning because we are working with leaflet low level logic
import {
  ArcDrawOptions,
  ArcProperties,
  CircleDrawOptions,
  EditableLayer,
  LineDrawOptions,
  MarkerDrawOptions,
  MotusArc,
  MotusCircle,
  MotusPolyline,
  MotusRobotMarker,
  MotusText,
  MotusTotalStationMarker,
  PointObject,
  RobotMarkerOptions,
  TextDrawOptions,
  TotalStationMarkerOptions
} from '../definitions';
import {
  Circle,
  CircleMarker,
  DomEvent,
  Handler,
  LatLng,
  LatLngBounds,
  LatLngLiteral,
  Layer,
  Map,
  Marker,
  Point,
  Polygon,
  Polyline,
  Rectangle,
  Util,
  bind,
  latLng,
  marker,
  point,
  setOptions
} from 'leaflet';

import fastdom from 'fastdom';
import { SVGArcLayer } from '../custom-svg-layers/svg-arc';

export interface ILeafletWrapper {
  createLine(
    oldOptions: LineDrawOptions,
    polylinePoints: LatLng[]
  ): MotusPolyline;
  createRectangle(bounds, options): Rectangle;
  createCircle(oldOptions: CircleDrawOptions, latLng: LatLng): MotusCircle;
  createCircleMarker(
    oldOptions: CircleDrawOptions,
    latLng: LatLng
  ): MotusCircle;
  createArc(oldOptions: ArcDrawOptions, polylinePoints: LatLng[]): MotusArc;
  createSvgArc(oldOptions: ArcDrawOptions): MotusArc;
  createText(options: TextDrawOptions, latLng: LatLng): MotusText;
  resizeRTStext(map: Map): number;
  createRobotMarker(
    options: MarkerDrawOptions,
    latLng: LatLng
  ): MotusRobotMarker;
  createTotalStationMarker(
    options: MarkerDrawOptions,
    latLng: LatLng
  ): MotusTotalStationMarker;
  toLatLng(x: number, y: number): LatLng;
  toXY(latLng: LatLng): PointObject;
  truncate(num: number, digits: number): number;
  fromRadiansToDegrees(angleRadian: number): number;
  fromDegreesToRadians(angleDegree: number): number;
  rotatePoint(p: PointObject, alfa: number): PointObject;
  scalePoint(p: PointObject, xScale: number, yScale: number): PointObject;
  addPoints(p1: PointObject, p2: PointObject): PointObject;
  normalizeAngle(angle: number): number;
  approximateArc(
    startAngle: number,
    endAngle: number,
    center: PointObject,
    radius: number
  ): LatLng[];
  bulgeToArc(
    bulge: number,
    startPoint: PointObject,
    endPoint: PointObject
  ): ArcProperties;
  getPerpendicularPoints(segment: Polyline, start: LatLngLiteral): LatLng[];
  getCollinearPoints(segment: Polyline, start: LatLngLiteral): LatLng[];
  overridePolyline(): void;
  overrideCircle(): void;
  overrideElements(): void;
  addTouchHandler(map: Map): void;
  isEditable(layer: Layer): layer is EditableLayer;
  calculateTextWidth(text: string, fontSize: number): number;
  calculatePoint(position: LatLng, angle: number, distance: number): LatLng;
}

export class LeafletWrapper implements ILeafletWrapper {
  public createRectangle(oldLatLngBounds, oldOptions): Rectangle {
    const rectangle: new (...args) => Rectangle = Rectangle.extend({
      initialize(latLngBounds, options) {
        if (latLngBounds.length === 4) {
          Polygon.prototype['initialize'].call(this, latLngBounds, options);
        } else {
          Polygon.prototype['initialize'].call(
            this,
            this._boundsToLatLngs(latLngBounds),
            options
          );
        }
      },
      _boundsToLatLngs(latLngBounds) {
        latLngBounds = this.toLatLngBounds(latLngBounds);
        return [
          latLngBounds.getSouthWest(),
          latLngBounds.getNorthWest(),
          latLngBounds.getNorthEast(),
          latLngBounds.getSouthEast()
        ];
      },
      toLatLngBounds: (a, b) => {
        if (a instanceof LatLngBounds) {
          return a;
        }
        return new LatLngBounds(a, b);
      }
    });
    return new rectangle(oldLatLngBounds, oldOptions);
  }

  public createLine(
    oldOptions: LineDrawOptions,
    polylinePoints: LatLng[]
  ): MotusPolyline {
    const line: new (...args) => MotusPolyline = Polyline.extend({
      initialize(options) {
        setOptions(this, options);
        Polyline.prototype['initialize'].call(this, polylinePoints);
      },
      onAdd(map) {
        this._map.on('zoomend', this.updateLabelSize, this);
        Polyline.prototype.onAdd.call(this, map);
        // add to map start point decorator if exists
        if (this.options.startDecorator) {
          this.options.startDecorator.addTo(map);
        }
        if (this.options.endDecorator) {
          this.options.endDecorator.addTo(map);
        }
      },
      setStyle(style) {
        Polyline.prototype.setStyle.call(this, style);
        // if start point decorator exists, set the same style as this line
        if (this.options.startDecorator) {
          if (
            'color' in style &&
            'decorBorder' in style &&
            'decorColor' in style
          ) {
            const tmp = {
              color: style.decorBorder,
              fillColor: style.decorColor
            };
            this.options.startDecorator.setStyle(tmp);
          } else {
            this.options.startDecorator.setStyle(style);
          }
        }
        if (this.options.endDecorator) {
          if (
            'color' in style &&
            'decorBorder' in style &&
            'decorColor' in style
          ) {
            const tmp = {
              color: style.decorBorder,
              fillColor: style.decorColor
            };
            this.options.endDecorator.setStyle(tmp);
          } else {
            this.options.endDecorator.setStyle(style);
          }
        }
      },
      updateLabelSize() {
        if (this.options.labelStyle) {
          const size = this.resizeRTStext(this._map);
          this.options.labelStyle.font = size + 'px sans-serif';
          this.options.labelStyle.offsetX = 10;
          this.options.labelStyle.offsetY = 10 + size / 2;
        }
      }
    });
    return new line(oldOptions);
  }

  resizeRTStext(map: Map): number {
    const scale =
      map.options.crs.scale(map.getZoom()) / map.options.unitConversionFactor;
    const MIN_SIZE = 20;
    const MAX_SIZE = 40;
    let size = (MIN_SIZE / 100) * scale;
    size = size < MIN_SIZE ? MIN_SIZE : size;
    size = size > MAX_SIZE ? MAX_SIZE : size;
    return size;
  }
  public createCircle(
    oldOptions: CircleDrawOptions,
    position: LatLng
  ): MotusCircle {
    const circle: new (...args) => MotusCircle = Circle.extend({
      initialize(options) {
        setOptions(this, options);
        Circle.prototype['initialize'].call(this, position);
      }
    });
    return new circle(oldOptions);
  }

  public createCircleMarker(
    oldOptions: CircleDrawOptions,
    position: LatLng
  ): MotusCircle {
    const circleMarker: new (...args) => MotusCircle = CircleMarker.extend({
      initialize(options) {
        setOptions(this, options);
        CircleMarker.prototype['initialize'].call(this, position);
      }
    });
    return new circleMarker(oldOptions);
  }

  public createArc(
    oldOptions: ArcDrawOptions,
    polylinePoints: LatLng[]
  ): MotusArc {
    const arc: new (...args) => MotusArc = Polyline.extend({
      initialize(options) {
        setOptions(this, options);
        Polyline.prototype['initialize'].call(this, polylinePoints);
      }
    });
    return new arc(oldOptions);
  }

  public createSvgArc(oldOptions: ArcDrawOptions): MotusArc {
    const arc: new (...args) => MotusArc = SVGArcLayer.extend({
      initialize(options) {
        setOptions(this, options);
      }
    });
    return new arc(oldOptions);
  }

  public createText(options: TextDrawOptions, position: LatLng): MotusText {
    const text: new (...args) => MotusText = Polyline.extend({
      getEvents() {
        return {
          zoomend: this.updateIconSize
        };
      },
      updateIconSize() {
        const scale = this._map.options.crs.scale(this._map.getZoom());
        const size = this.options.textSize * scale * 1.4;

        this.options.labelStyle.font = size + 'px sans-serif';

        //dynamic set weight of polyline
        this.setStyle({ weight: size * 0.5 });
      }
    });

    // Options for the text of the CanvasLabel

    options.labelStyle = {
      text: options.text,
      collisionFlg: false,
      scale: 1,
      rotation:
        options.rotationAngle != null
          ? (options.rotationAngle * Math.PI) / 180.0
          : 0,
      zIndex: 0,
      lineWidth: options.weight / 4,
      fillStyle: options.color,
      strokeStyle: options.color,
      //Where the text is oriented from his origin point
      textAlign: 'left',
      //Where the is vertically aligned from his origin point
      textBaseline: 'middle',
      offsetX: options.textAnchor[0],
      offsetY: options.textAnchor[1],
      opacity: options.opacity ?? 1
    };

    //We get the width of the text using a temporary canvas
    const textCanvasWidth = this.calculateTextWidth(
      options.text,
      options.textSize
    );

    //We get the perpendicularAngle to get the angle of the origin point
    const perpendicularAngle = options.rotationAngle - 90;

    //We calculate the midPoint to avoid unnecessary distance from the original point from DXF
    const midPoint = this.calculatePoint(
      position,
      perpendicularAngle,
      options.textSize / 2
    );

    //We calculate the finalPoint to have a Polyline with 2 point for the bounds of the text
    const finalPoint = this.calculatePoint(
      midPoint,
      options.rotationAngle,
      textCanvasWidth
    );

    //Setting the pending options of the Polyline
    options.color = 'transparent';
    options.weight = 8;
    options.opacity = 0;
    options.dashArray = null;

    return new text([midPoint, finalPoint], {
      ...options,
      labelStyle: options.labelStyle
    });
  }

  // Function that generates a temporary canvas to calculate the width of a given text and a fontsize
  public calculateTextWidth(text: string, fontSize: number): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      //we multiply by 100 to increase temporary small fonts
      context.font = fontSize * 100 + 'px sans-serif';
      // we divide by 100 to normalize the temporary font increase
      const width = (context.measureText(text).width * 1.4) / 100;

      canvas.remove();

      return width;
    }
    return 0;
  }

  //function that calculates a point with a given point, angle and distance
  public calculatePoint(
    position: LatLng,
    angle: number,
    distance: number
  ): LatLng {
    const angleRad = (angle * Math.PI) / 180.0;
    const offsetX = Math.cos(angleRad) * distance;
    const offsetY = Math.sin(angleRad) * distance;
    return new LatLng(position.lat - offsetY, position.lng + offsetX);
  }

  public createRobotMarker(
    options: RobotMarkerOptions,
    position: LatLng
  ): MotusRobotMarker {
    const robotMarker: new (
      pos: LatLng,
      opts: RobotMarkerOptions
    ) => MotusRobotMarker = Marker.extend({
      getEvents() {
        const defaultEvents = Marker.prototype.getEvents.call(this);
        return {
          ...defaultEvents,
          zoomend: this.updateIconSize
        };
      },
      onAdd(map: Map) {
        Marker.prototype.onAdd.call(this, map);
        this.updateIconSize();
      },
      updateIconSize() {
        const scale =
          this._map.options.crs.scale(this._map.getZoom()) /
          this._map.options.unitConversionFactor;
        const width = this.options.robotRealSize[0] * scale;
        const height = this.options.robotRealSize[1] * scale;
        let newAnchor = [
          this.options.robotMarkerMinWidth * this.options.anchorPercent[0],
          this.options.robotMarkerMinHeight * this.options.anchorPercent[1]
        ];
        let newSize = [
          this.options.robotMarkerMinWidth,
          this.options.robotMarkerMinHeight
        ];
        // calculate new icon size
        // calculate icon anchor point
        if (
          width >= this.options.robotMarkerMinWidth &&
          height >= this.options.robotMarkerMinHeight
        ) {
          newAnchor = [
            width * this.options.anchorPercent[0],
            height * this.options.anchorPercent[1]
          ];
          newSize = [width, height];
        }
        this.options.icon.options.iconSize = newSize;
        this.options.icon.options.iconAnchor = newAnchor;
        fastdom.mutate(() => {
          if (this._icon) {
            if (newAnchor) {
              this._icon.style.marginLeft = `${-newAnchor[0]}px`;
              this._icon.style.marginTop = `${-newAnchor[1]}px`;
            }
            if (newSize) {
              this._icon.style.width = `${newSize[0]}px`;
              this._icon.style.height = `${newSize[1]}px`;
            }
          }
        });
      }
    });
    return new robotMarker(position, options);
  }

  public createTotalStationMarker(
    options: TotalStationMarkerOptions,
    position: LatLng
  ): MotusTotalStationMarker {
    const totalStationMarker: new (
      pos: LatLng,
      opts: TotalStationMarkerOptions
    ) => MotusTotalStationMarker = Marker.extend({
      getEvents() {
        const defaultEvents = Marker.prototype.getEvents.call(this);
        return {
          ...defaultEvents,
          zoomend: this.updateIconSize
        };
      },
      setLatLng(newPosition: LatLng) {
        Marker.prototype.setLatLng.call(this, newPosition);
        this.options.minDistanceIndicator?.setLatLng(newPosition);
      },
      updateIconSize(): void {
        const scale =
          this._map.options.crs.scale(this._map.getZoom()) /
          this._map.options.unitConversionFactor;
        const width = (this.options.totalStationIconSize[0] / 100) * scale;
        const height = (this.options.totalStationIconSize[1] / 100) * scale;
        let newAnchor = [
          this.options.totalStationMarkerMinWidth / 2,
          this.options.totalStationMarkerMinHeight
        ];
        let newSize = [
          this.options.totalStationMarkerMinWidth,
          this.options.totalStationMarkerMinHeight
        ];
        // calculate icon anchor point
        if (
          width >= this.options.totalStationMarkerMinWidth &&
          height >= this.options.totalStationMarkerMinHeight
        ) {
          newAnchor = [width / 2, height];
          newSize = [width, height];
        }
        this.options.icon.options.iconSize = newSize;
        this.options.icon.options.iconAnchor = newAnchor;
        fastdom.mutate(() => {
          if (this._icon) {
            if (newAnchor) {
              this._icon.style.marginLeft = `${-newAnchor[0]}px`;
              this._icon.style.marginTop = `${-newAnchor[1]}px`;
            }
            if (newSize) {
              this._icon.style.width = `${newSize[0]}px`;
              this._icon.style.height = `${newSize[1]}px`;
            }
          }
        });
      },
      onAdd(map: Map) {
        Marker.prototype.onAdd.call(this, map);
        this.options.minDistanceIndicator?.addTo(map);
        this.updateIconSize();
      },
      changeIconDarkMode() {
        options.icon.options.html =
          '<img class="svg-total-station-icon" src="assets/images/app/features/map/icon_ts1.svg"/>';
        this.setIcon(options.icon);
      },
      changeIconLightMode() {
        options.icon.options.html =
          '<img class="svg-total-station-icon" src="assets/images/app/features/map/icon_ts1.svg"/>';
        this.setIcon(options.icon);
      }
    });
    return new totalStationMarker(position, options);
  }

  public toLatLng(x: number, y: number): LatLng {
    return latLng(y, x);
  }

  public toXY(ll: LatLng): PointObject {
    return new Point(ll.lng, ll.lat);
  }

  public truncate(num: number, digits: number): number {
    const multiplier = Math.pow(10, digits);
    const adjustedNum = num * multiplier;
    const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
  }

  public fromRadiansToDegrees(angleRadian: number): number {
    return angleRadian != null ? (angleRadian * 180.0) / Math.PI : 0;
  }

  public fromDegreesToRadians(angleDegree: number): number {
    return angleDegree != null ? (angleDegree * Math.PI) / 180.0 : 0;
  }

  public rotatePoint(p: PointObject, alfa: number): PointObject {
    const rotatedPoint = point({
      x: p.x * Math.cos(alfa) - p.y * Math.sin(alfa),
      y: p.x * Math.sin(alfa) + p.y * Math.cos(alfa)
    });
    return rotatedPoint;
  }

  public scalePoint(
    p: PointObject,
    xScale: number,
    yScale: number
  ): PointObject {
    const scaledPoint = point({
      x: p.x * xScale,
      y: p.y * yScale
    });
    return scaledPoint;
  }

  public addPoints(p1: PointObject, p2: PointObject): PointObject {
    const newPoint = point({
      x: p1.x + p2.x,
      y: p1.y + p2.y
    });
    return newPoint;
  }

  public normalizeAngle(angle: number): number {
    let angleNorm = angle;
    while (angleNorm < 0) {
      angleNorm += 2 * Math.PI;
    }

    while (angleNorm > 2 * Math.PI) {
      angleNorm -= 2 * Math.PI;
    }

    return angleNorm;
  }

  public approximateArc(
    startAngle: number,
    endAngle: number,
    center: PointObject,
    radius: number
  ): LatLng[] {
    const polylinePoints: LatLng[] = [];
    let tmpEndAngle = endAngle;
    let targetX = 0;
    let targetY = 0;

    if (endAngle < startAngle) {
      tmpEndAngle = endAngle + 2 * Math.PI;
    }

    for (let theta = startAngle; theta <= tmpEndAngle; theta += 0.05) {
      targetX = center.x + radius * Math.cos(theta);
      targetY = center.y + radius * Math.sin(theta);
      polylinePoints.push(this.toLatLng(targetX, targetY));
    }

    // add final p of the arc
    targetX = center.x + radius * Math.cos(tmpEndAngle);
    targetY = center.y + radius * Math.sin(tmpEndAngle);
    polylinePoints.push(this.toLatLng(targetX, targetY));

    return polylinePoints;
  }

  public bulgeToArc(
    bulge: number,
    startPoint: PointObject,
    endPoint: PointObject
  ): ArcProperties {
    const x1 = startPoint.x;
    const x2 = endPoint.x;
    const y1 = startPoint.y;
    const y2 = endPoint.y;

    // let includedAngle = 4.0 * Math.atan(bulge);
    // if (bulge > 0.0) {
    //   includedAngle = 2 * Math.PI - includedAngle;
    // }

    const dcx = (x1 + x2) / 2.0;
    const dcy = (y1 + y2) / 2.0;
    const dx = x2 - x1;
    const dy = y2 - y1;
    let d = dx * dx + dy * dy;

    const prop = {
      radius: 0.0,
      center: point({ x: 0.0, y: 0.0 }),
      startAngle: 0.0,
      endAngle: 0.0
    };

    if (d > 0.00001) {
      const a12 = Math.atan2(dy, dx);
      d = Math.sqrt(d);
      const h = (bulge * d) / 2.0;
      const radius = Math.abs((d / 4.0) * (bulge + 1.0 / bulge));
      let rh = radius - Math.abs(h);
      if (bulge < 0.0) {
        rh = -rh;
      }

      const center = point({
        x: dcx + rh * Math.cos(a12 + Math.PI / 2),
        y: dcy + rh * Math.sin(a12 + Math.PI / 2)
      });
      let startAngle = Math.atan2(y1 - center.y, x1 - center.x);
      let endAngle = Math.atan2(y2 - center.y, x2 - center.x);

      if (bulge > 0.0) {
        // arc goes CCW
        // end angle - start_angle has to be > 0
        if (endAngle < startAngle) {
          endAngle += 2 * Math.PI;
        }
      } else {
        // arc goes CW
        // end angle - start_angle has to be < 0
        if (endAngle > startAngle) {
          endAngle -= 2 * Math.PI;
        }
      }

      // Angles in DXF Arc entities cannot be negative
      while (startAngle < 0) {
        startAngle += 2 * Math.PI;
      }
      while (endAngle < 0) {
        endAngle += 2 * Math.PI;
      }

      // swap if arc goes CW
      if (bulge < 0.0) {
        const tempValue = startAngle;
        startAngle = endAngle;
        endAngle = tempValue;
        bulge = -1 * bulge;
      }
      prop.radius = radius;
      prop.center = center;
      prop.startAngle = startAngle;
      prop.endAngle = endAngle;
    } else {
      console.warn('Resulting ARC would be very small: %f', d);
    }
    return prop;
  }

  public getPerpendicularPoints(
    segment: Polyline,
    start: LatLngLiteral
  ): LatLng[] {
    const latLngs = segment.getLatLngs() as LatLng[];
    if (latLngs && latLngs.length === 2) {
      const x = latLngs[0].lng - latLngs[1].lng;
      const y = latLngs[0].lat - latLngs[1].lat;
      const conversionFactor = 500 / Math.sqrt(x ** 2 + y ** 2);
      //Convert using the opposite coordinate ps
      const lat1 = start.lat + conversionFactor * x;
      const lng1 = start.lng - conversionFactor * y;
      const lat2 = start.lat - conversionFactor * x;
      const lng2 = start.lng + conversionFactor * y;
      return [new LatLng(lat1, lng1), new LatLng(lat2, lng2)];
    }
    return null;
  }

  public getCollinearPoints(segment: Polyline, start: LatLngLiteral): LatLng[] {
    const latLngs = segment.getLatLngs() as LatLng[];
    if (latLngs && latLngs.length === 2) {
      const x = latLngs[0].lng - latLngs[1].lng;
      const y = latLngs[0].lat - latLngs[1].lat;
      const conversionFactor = 500 / Math.sqrt(x ** 2 + y ** 2);
      const lat1 = start.lat + conversionFactor * y;
      const lng1 = start.lng + conversionFactor * x;
      const lat2 = start.lat - conversionFactor * y;
      const lng2 = start.lng - conversionFactor * x;
      return [new LatLng(lat1, lng1), new LatLng(lat2, lng2)];
    }
    return null;
  }

  public isEditable(layer: Layer): layer is EditableLayer {
    return (
      layer instanceof Polyline ||
      layer instanceof Circle ||
      layer instanceof Marker ||
      layer instanceof Polygon ||
      layer instanceof Rectangle
    );
  }

  public addTouchHandler(map: Map): void {
    const onTouch = function (event: TouchEvent) {
      if (
        !this.map['_loaded'] ||
        event.target['classList'].contains('leaflet-marker-draggable') ||
        event.targetTouches.length > 1
      ) {
        return;
      }
      const containerPoint = this.map.mouseEventToContainerPoint(
        new MouseEvent(event.type, event.touches.item(0))
      );
      const layerPoint = this.map.containerPointToLayerPoint(containerPoint);
      const latlng = this.map.layerPointToLatLng(layerPoint);
      this.map.fire(event.type, {
        latlng,
        layerPoint,
        containerPoint,
        originalEvent: event
      });
    };

    const touchHandler = Handler.extend({
      addHooks() {
        this.map = map;
        this.container = map.getContainer();
        DomEvent.on(this.container, 'touchstart', onTouch, this);
        DomEvent.on(this.container, 'touchmove', onTouch, this);
        DomEvent.on(this.container, 'touchend', onTouch, this);
      },
      removeHooks() {
        DomEvent.off(this.container, 'touchstart', onTouch, this);
        DomEvent.off(this.container, 'touchmove', onTouch, this);
        DomEvent.off(this.container, 'touchend', onTouch, this);
      }
    });
    map.addHandler('touchExtend', touchHandler);
  }

  public overrideElements(): void {
    this.overrideCircle();
    this.overridePolyline();
  }

  public overridePolyline(): void {
    Polyline.include({
      // Just added !this.measurementLayer to original code: https://github.com/ProminentEdge/leaflet-measure-path/blob/master/leaflet-measure-path.js
      hideMeasurements() {
        if (!this._map || !this._measurementLayer) return this;

        this._map.off('zoomend', this.updateMeasurements, this);
        this._map.removeLayer(this._measurementLayer);
        this._measurementLayer = null;

        return this;
      },
      updateMeasurements() {
        if (!this._measurementLayer) return this;

        let latLngs = this.getLatLngs();
        const isPolygon = this instanceof Polygon;
        const options = this._measurementOptions;
        let totalDist = 0;
        let formatter;
        let ll1;
        let ll2;
        let p1;
        let p2;
        let pixelDist;
        let dist;

        if (latLngs && latLngs.length && Util.isArray(latLngs[0])) {
          // Outer ring is stored as an array in the first element,
          // use that instead.
          latLngs = latLngs[0];
        }

        this._measurementLayer.clearLayers();

        if (this._measurementOptions.showDistances && latLngs.length > 1) {
          formatter =
            this._measurementOptions.formatDistance ||
            bind(this.formatDistance, this);

          for (
            let i = 1, len = latLngs.length;
            (isPolygon && i <= len) || i < len;
            i++
          ) {
            ll1 = latLngs[i - 1];
            ll2 = latLngs[i % len];
            dist = this._map.distance(ll1, ll2);
            totalDist += dist;

            p1 = this._map.latLngToLayerPoint(ll1);
            p2 = this._map.latLngToLayerPoint(ll2);

            pixelDist = p1.distanceTo(p2);

            if (pixelDist >= options.minPixelDistance) {
              marker['measurement'](
                this._map.layerPointToLatLng([
                  (p1.x + p2.x) / 2,
                  (p1.y + p2.y) / 2
                ]),
                formatter(dist),
                options.lang.segmentLength,
                this._getRotation(ll1, ll2),
                options
              ).addTo(this._measurementLayer);
            }
          }

          // Show total length for polylines
          if (!isPolygon && this._measurementOptions.showTotalDistance) {
            marker['measurement'](
              ll2,
              formatter(totalDist),
              options.lang.totalLength,
              0,
              options
            ).addTo(this._measurementLayer);
          }
        }
      }
    });
  }
  overrideCircle() {
    Circle.include({
      // Just added !this.measurementLayer to original code: https://github.com/ProminentEdge/leaflet-measure-path/blob/master/leaflet-measure-path.js
      hideMeasurements() {
        if (!this._map || !this._measurementLayer) return this;

        this._map.off('zoomend', this.updateMeasurements, this);
        this._map.removeLayer(this._measurementLayer);
        this._measurementLayer = null;

        return this;
      },

      _containsPoint(p: Point): boolean {
        if (this.options.fill) {
          return CircleMarker.prototype['_containsPoint'].call(this, p);
          //return p.distanceTo(this.point) <= this._radius + this._clickTolerance();
        } else {
          const centerToPointDistance: number = p.distanceTo(this._point);
          const isPointInBounds: boolean =
            centerToPointDistance >= this._radius - this._clickTolerance() &&
            centerToPointDistance <= this._radius + this._clickTolerance();
          return isPointInBounds;
        }
      }
    });
  }
}
