import { CRS, PointTuple } from 'leaflet';
import {
  ElementTypeEnum,
  LayerElementsEnum,
  UnitsAcronymEnum,
  UnitsValuesEnum
} from './map.enums';
import {
  LayerElementsMapper,
  MapExtendedOptions,
  RobotLayersProperties,
  ToolTopInfoPropertiesMapper,
  UnitConversionMapper
} from './definitions';

export class MapConstants {
  public readonly FULL_MAP_STORAGE_KEY = 'print.fullMap';
  public readonly JOB_STORAGE_KEY = 'print.job';
  public readonly MAP_ZOOM_END_EVENT_NAME = 'zoomend';
  public readonly MAP_ZOOM_DRAG_EVENT_NAME = 'zoom drag';
  public readonly MAP_ZOOM_START_EVENT_NAME = 'zoomstart';
  public readonly MAP_CONTAINER_ID = 'map';
  public readonly DXF_STORAGE_KEY = 'dxf.dxf';
  public readonly DXFINFO_STORAGE_KEY = 'dxf.dxfInfo';
  public readonly MAP_DASH_SIZE = '5';
  public readonly MAP_PRINT_COLOR = '#0000FF';
  public readonly MAP_PRINTED_COLOR = '#00FF00';
  public readonly MAP_PARTIALLY_PRINTED_COLOR = '#ffc800';
  public readonly MANUAL = 'manualMode';
  public readonly MAP_TOUCH_TOLERANCE = 15;
  public readonly MAP_RULER_SNAP_TOLERANCE = 30;
  public readonly MAP_CLOSEST_CHECK_DELAY = 300;
  //Robot marker min width
  public readonly ROBOT_MARKER_MIN_WIDTH: number = 45;
  //Robot marker min height
  public readonly ROBOT_MARKER_MIN_HEIGHT: number = 30;
  //Total station marker min width
  //Used for 'reset' min zoom level supported
  public readonly MIN_ZOOM_SUPPORTED = -100000;
  // Real robot size in meters
  public readonly MOTUS_REAL_SIZE: PointTuple = [0.52, 0.37];

  /**
   * Controls the weight of select area feature
   */
  public readonly SELECT_AREA_WIDTH: number = 3;
  /**
   * Controls the color of select area feature
   */
  public readonly SELECT_AREA_COLOR: string = '#0096D6';
  /**
   * Real icon size in pixels;
   */
  public readonly ROBOT_ICON_SIZE: PointTuple = [36, 32];
  /**
   * Percentage of the icon size to represents the anchor point - mid point of the icon
   * Number between 0 and 1
   */
  public readonly ACHOR_SCALE: PointTuple = [0.76, 0.5];

  /**
   * Total station icon size in pixels;
   */
  public readonly TOTAL_STATION_ICON_SIZE: PointTuple = [40, 40];

  /**
   * Toal station icon size in pixels;
   */
  public readonly TOTAL_STATION_ICON_ANCHOR: PointTuple = [20, 40];
  //total station marker min width
  public readonly TOTAL_STATION_MIN_WIDTH: number = 40;
  //Total station marker min height
  public readonly TOTAL_STATION_MIN_HEIGHT: number = 40;
  /**
   * Controls layers properties, such as color.
   * The 'other' is used for all other layers that are not these three
   */
  public readonly LAYER_PROPERTIES: RobotLayersProperties = {
    paint: {
      color: '#0040ff',
      colorNotSelected: '#88919e',
      weight: 4,
      textSize: 12
    },
    total_station_ref_axis: {
      color: '#9FE5FF',
      colorStartPoint: '#000000',
      colorNotSelected: '#014667',
      weight: 4
    },
    discarded: {
      color: '#ff00ff',
      colorNotSelected: '#ff00ff',
      weight: 2,
      textSize: 12
    },
    paint_old: {
      color: '#F3F8F8',
      colorNotSelected: '#F3F8F8',
      weight: 2,
      textSize: 12
    },
    obstacles: { color: '#FF0000', weight: 1, textSize: 10 },
    obstacles_edit: { color: '#FF0000', weight: 1, textSize: 10 },
    other: { color: '#ccc', weight: 1, textSize: 10 }
  };

  public readonly DEFAULT_RULER_OPTIONS = { color: '#027AAE' };
  public readonly TOOL_TOP_INFO_PROPERTIES: ToolTopInfoPropertiesMapper = {
    ruler: { color: '#027AAE', text: 'SHARED_MAP_MESUREMENT_TOP_INFO' }
  };
  public readonly DEFAULT_UNIT = 1;

  public readonly UNIT_CONVERSION_VALUE: UnitConversionMapper<number> = {
    [UnitsValuesEnum.inches]: 0.0254,
    [UnitsValuesEnum.feet]: 0.3048,
    [UnitsValuesEnum.milimeters]: 0.001,
    [UnitsValuesEnum.centimeters]: 0.01,
    [UnitsValuesEnum.meters]: 1,
    [UnitsValuesEnum.decimeters]: 0.1,
    [UnitsValuesEnum.surveyFeet]: 0.3048006096
  };

  public readonly UNIT_CONVERSION_IDENTIFIER: UnitConversionMapper<string> = {
    [UnitsValuesEnum.feet]: 'foot',
    [UnitsValuesEnum.inches]: 'inch',
    [UnitsValuesEnum.milimeters]: 'millimeter',
    [UnitsValuesEnum.centimeters]: 'centimeter',
    [UnitsValuesEnum.meters]: 'meter',
    [UnitsValuesEnum.surveyFeet]: 'foot'
  };

  public readonly UNIT_CONVERSION_SHORT_IDENTIFIER: UnitConversionMapper<UnitsAcronymEnum> =
    {
      [UnitsValuesEnum.feet]: UnitsAcronymEnum.feet,
      [UnitsValuesEnum.inches]: UnitsAcronymEnum.inches,
      [UnitsValuesEnum.milimeters]: UnitsAcronymEnum.milimeters,
      [UnitsValuesEnum.centimeters]: UnitsAcronymEnum.centimeters,
      [UnitsValuesEnum.meters]: UnitsAcronymEnum.meters
    };

  public readonly MAP_DEFAULT_PROPERTIES: MapExtendedOptions = {
    editable: true,
    editOptions: {
      skipMiddleMarkers: true
    },
    crs: CRS.Simple,
    attributionControl: false,
    zoomAnimation: true,
    minZoom: -1000,
    maxZoom: 14,
    zoomSnap: 0.5,
    zoomDelta: 0.5,
    tap: true,
    touchExtend: true,
    pmIgnore: false
  };

  public readonly GUIDELINES_DEFAULT_OPTIONS = {
    color: 'orange',
    dashArray: '1 2',
    weight: 1
  };

  public readonly ELEMENT_TYPE_LAYER: LayerElementsMapper = {
    [ElementTypeEnum.LINE]: LayerElementsEnum.SEGMENTS,
    [ElementTypeEnum.ARC]: LayerElementsEnum.ARCS,
    [ElementTypeEnum.POINT]: LayerElementsEnum.POINTS,
    [ElementTypeEnum.CIRCLE]: LayerElementsEnum.CIRCLES,
    [ElementTypeEnum.TEXT]: LayerElementsEnum.TEXTS,
    [ElementTypeEnum.MTEXT]: LayerElementsEnum.TEXTS,
    [ElementTypeEnum.LWPOLYLINE]: LayerElementsEnum.SEGMENTS
  };
  public readonly TS_MIN_INDICATOR_RADIUS = 4;
}
