import '../leaflet.definitions';

import {
  ArcDrawOptions,
  ArcProperties,
  CircleDrawOptions,
  LineDrawOptions,
  MapExtendedOptions,
  MarkerDrawOptions,
  MotusArc,
  MotusCircle,
  MotusPolyline,
  MotusRobotMarker,
  MotusText,
  MotusTotalStationMarker,
  PointObject,
  RobotMarkerOptions,
  TextDrawOptions,
  TotalStationMarkerOptions
} from './../definitions';
import {
  CanvasLabel,
  DivIcon,
  FeatureGroup,
  LatLng,
  LatLngLiteral,
  LeafletMouseEvent,
  Map,
  Polyline,
  map,
  point
} from 'leaflet';
import { LayersEnum, UnitsValuesEnum } from '../map.enums';

import { LeafletWrapper } from '../leaflet-wrapper/leaflet-wrapper.service';
import { MapConstants } from '../map.constants';

export interface IMapHelper {
  drawLine(options: LineDrawOptions): MotusPolyline;
  drawCircle(options: CircleDrawOptions): MotusCircle;
  drawCircleMarker(options: CircleDrawOptions): MotusCircle;
  drawArc(options: ArcDrawOptions): MotusArc;
  drawText(options: TextDrawOptions): MotusText;
  drawRobotMarker(options: MarkerDrawOptions): MotusRobotMarker;
  drawTotalStationMarker(options: MarkerDrawOptions): MotusTotalStationMarker;
  toLatLng(x: number, y: number): LatLng;
  toXY(latLng: LatLng): PointObject;
  truncate(num: number, digits: number): number;
  fromRadiansToDegrees(angleRadian: number): number;
  fromDegreesToRadians(angleDegree: number): number;
  rotatePoint(p: PointObject, alfa: number): PointObject;
  scalePoint(p: PointObject, xScale: number, yScale: number): PointObject;
  addPoints(p1: PointObject, p2: PointObject): PointObject;
  normalizeAngle(angle: number): number;
  approximateArc(
    startAngle: number,
    endAngle: number,
    center: PointObject,
    radius: number
  ): LatLng[];
  bulgeToArc(
    bulge: number,
    startPoint: PointObject,
    endPoint: PointObject
  ): ArcProperties;
  getPerpendicularPoints(segment: Polyline, start: LatLngLiteral): LatLng[];
  getCollinearPoints(segment: Polyline, start: LatLngLiteral);
  changeElementVisibility(element, show: boolean): void;
  initMap(unit: UnitsValuesEnum, mapOptions?: MapExtendedOptions): Map;
}

// Custom DivIcon
export const divIcon: new (...args) => DivIcon = DivIcon.extend({
  createIcon(oldIcon) {
    const div = DivIcon.prototype.createIcon.call(this, oldIcon);
    if (this.options.style) {
      for (const key in this.options.style) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.options.style.hasOwnProperty(key)) {
          div.style[key] = this.options.style[key];
        }
      }
    }
    return div;
  }
});

export class MapHelper implements IMapHelper {
  private leafletWrapper: LeafletWrapper;
  private constants: MapConstants;
  constructor(leafletWrapper: LeafletWrapper, constants: MapConstants) {
    this.leafletWrapper = leafletWrapper;
    this.constants = constants;
  }
  /**
   * Line to draw in leaflet
   *
   * @param options Object of properties
   * @returns Leaflet Polyline object
   */
  drawLine(oldOptions: LineDrawOptions): MotusPolyline {
    let polylinePoints = [];
    // if this segment has a non-zero bulge value, then need to draw it as a curve segment
    if (oldOptions.bulge !== 0) {
      const arcProp = this.bulgeToArc(
        oldOptions.bulge,
        oldOptions.start,
        oldOptions.end
      );
      polylinePoints = this.approximateArc(
        arcProp.startAngle,
        arcProp.endAngle,
        arcProp.center,
        arcProp.radius
      );
    } else {
      polylinePoints.push(
        this.toLatLng(oldOptions.start.x, oldOptions.start.y)
      );
      polylinePoints.push(this.toLatLng(oldOptions.end.x, oldOptions.end.y));
    }
    oldOptions.smoothFactor = 2;
    return this.leafletWrapper.createLine(oldOptions, polylinePoints);
  }

  /**
   * Circle to draw in leaflet
   *
   * @param options Object of properties
   * @returns Leaflet Circle object
   */
  drawCircle(oldOptions: CircleDrawOptions): MotusCircle {
    if (!oldOptions.center) {
      oldOptions.center = point(0, 0);
    }
    const latLng = this.toLatLng(oldOptions.center.x, oldOptions.center.y);
    return this.leafletWrapper.createCircle(oldOptions, latLng);
  }

  /**
   * Circle Marker to draw in leaflet
   *
   * @param options Object of properties
   * @returns Leaflet Circle Marker object
   */
  drawCircleMarker(oldOptions: CircleDrawOptions): MotusCircle {
    if (!oldOptions.center) {
      oldOptions.center = point(0, 0);
    }
    const latLng = this.toLatLng(oldOptions.center.x, oldOptions.center.y);
    return this.leafletWrapper.createCircleMarker(oldOptions, latLng);
  }

  /**
   * Arc to draw in leaflet
   *
   * @param options Object of properties
   * @returns Leaflet Polyline object (arc approximation by lines)
   */
  drawArc(oldOptions: ArcDrawOptions): MotusArc {
    oldOptions.smoothFactor = 2;
    return this.leafletWrapper.createSvgArc(oldOptions);
  }

  /**
   * Text to draw in leaflet
   *
   * @param options Object of properties
   * @returns Leaflet Marker object (text as marker)
   */
  drawText(oldOptions: TextDrawOptions): MotusText {
    const latLng = this.toLatLng(oldOptions.insert.x, oldOptions.insert.y);
    oldOptions.rotationAngle = -this.fromRadiansToDegrees(
      oldOptions.rotationAngle
    );
    oldOptions.textAnchor = [
      oldOptions.textSize *
        -Math.sin(this.fromDegreesToRadians(oldOptions.rotationAngle)),
      oldOptions.textSize *
        Math.cos(this.fromDegreesToRadians(oldOptions.rotationAngle))
    ];
    return this.leafletWrapper.createText(oldOptions, latLng);
  }

  /**
   * Robot marker to draw in leaflet
   *
   * @param options Object of properties
   * @returns Leaflet Marker object (with icon of the robot)
   */
  drawRobotMarker(options: RobotMarkerOptions): MotusRobotMarker {
    const latLng = this.toLatLng(options.position[0], options.position[1]);
    options.rotationAngle =
      -1 * this.fromRadiansToDegrees(options.rotationAngle);
    options.icon = new divIcon({
      html: '<img class="svg-motus-icon" src="assets/images/app/features/map/motus_icon.svg"/>',
      className: 'robot-marker',
      iconSize: options.iconSize,
      iconAnchor: options.iconAnchor
    });
    return this.leafletWrapper.createRobotMarker(options, latLng);
  }
  /**
   * Total station marker to draw in leaflet
   *
   * @param options Object of properties
   * @returns Leaflet Marker object (with icon of the total station)
   */
  drawTotalStationMarker(
    options: TotalStationMarkerOptions
  ): MotusTotalStationMarker {
    const latLng = this.toLatLng(options.position[0], options.position[1]);
    let htmlIcon =
      '<img class="svg-total-station-icon" src="assets/images/app/features/map/icon_ts1.svg"/>';
    if (options.mode) {
      htmlIcon =
        '<img class="svg-total-station-icon" src="assets/images/app/features/map/icon_ts1.svg"/>';
    }
    options.minDistanceIndicator ??= this.drawCircle({
      center: point(options.position[0], options.position[1]),
      radius:
        this.constants.TS_MIN_INDICATOR_RADIUS /
        this.constants.UNIT_CONVERSION_VALUE[options.unit],
      color: this.constants.LAYER_PROPERTIES.total_station_ref_axis.color,
      fillColor: this.constants.LAYER_PROPERTIES.total_station_ref_axis.color,
      weight: 0,
      fillOpacity: 0.1,
      interactive: false,
      dashArray: null,
      pattern: '',
      lineweight: 0
    });
    options.icon = new divIcon({
      html: htmlIcon,
      className: 'total-station-marker',
      iconSize: options.iconSize,
      iconAnchor: options.iconAnchor
    });

    return this.leafletWrapper.createTotalStationMarker({ ...options }, latLng);
  }
  /**
   * Convert an (x, y) coordinate into (lat, long) point
   *
   * @param x X-axis coordinate
   * @param y Y-axis coordinate
   * @returns Leaflet lagLng point
   */
  toLatLng(x: number, y: number): LatLng {
    // return latLng(y, x);
    return this.leafletWrapper.toLatLng(x, y);
  }
  /**
   * Convert a (lat, long) point into an (x, y) coordinate point
   *
   * @param latlng Leaflet lagLng point
   * @returns An object with x and y points
   */
  toXY(latLng: LatLng): PointObject {
    return this.leafletWrapper.toXY(latLng);
  }
  /**
   * Truncate given number to given digits
   *
   * @param num Number to truncate
   * @param digits Number of digits after decimal point
   * @returns The truncated number
   */
  truncate(num: number, digits: number): number {
    return this.leafletWrapper.truncate(num, digits);
  }
  /**
   * Convert angle from radians to degrees
   *
   * @param angleRadian Angle in radians
   * @returns The Angle in degrees
   */
  fromRadiansToDegrees(angleRadian: number): number {
    return this.leafletWrapper.fromRadiansToDegrees(angleRadian);
  }
  /**
   * Convert angle from degrees to radians
   *
   * @param angleDegree Angle in degrees
   * @returns The Angle in radians
   */
  fromDegreesToRadians(angleDegree: number): number {
    return this.leafletWrapper.fromDegreesToRadians(angleDegree);
  }
  /**
   * Return a point rotated around origin (0,0) by specified angle radians
   *
   * @param point Input to rotate
   * @param alfa  Angle in radians to perform rotation
   * @returns The rotated point coordinates
   */
  rotatePoint(p: PointObject, alfa: number): PointObject {
    return this.leafletWrapper.rotatePoint(p, alfa);
  }
  scalePoint(p: PointObject, xScale: number, yScale: number): PointObject {
    return this.leafletWrapper.scalePoint(p, xScale, yScale);
  }
  /**
   * Vectorial sum of two points in 2D
   *
   * @param p1 First point
   * @param p2 Second point
   * @returns Result point as result of vector sum
   */
  addPoints(p1: PointObject, p2: PointObject): PointObject {
    return this.leafletWrapper.addPoints(p1, p2);
  }
  // normalize angles between 0-2*PI
  normalizeAngle(angle: number): number {
    return this.leafletWrapper.normalizeAngle(angle);
  }
  /**
   * Approximate an arc with polylines
   *
   * @param startAngle Start angle (in radians)
   * @param endAngle End angle (in radians)
   * @param center Center point of the arc (an object that contains x and y. e.x: {x: 1, y: 1})
   * @param radius Radius of the arc
   * @returns An array of polylines (converted to latlng) describing the arc
   */
  approximateArc(
    startAngle: number,
    endAngle: number,
    center: PointObject,
    radius: number
  ): LatLng[] {
    return this.leafletWrapper.approximateArc(
      startAngle,
      endAngle,
      center,
      radius
    );
  }
  /**
   * Create an arc properties from a segment with start, end point and bulge value
   *
   * @param bulge Segment curvature value
   * @param startPoint Start point of the segment (an object that contains x and y. e.x: {x: 1, y: 1})
   * @param endPoint End point of the segment (an object that contains x and y. e.x: {x: 1, y: 1})
   * @returns An object with arc properties: radius, center, startAngle and endAngle
   */
  bulgeToArc(
    bulge: number,
    startPoint: PointObject,
    endPoint: PointObject
  ): ArcProperties {
    return this.leafletWrapper.bulgeToArc(bulge, startPoint, endPoint);
  }

  /**
   *
   * @param segment
   * @param start
   * @returns
   */
  getPerpendicularPoints(segment: Polyline, start: LatLngLiteral): LatLng[] {
    return this.leafletWrapper.getPerpendicularPoints(segment, start);
  }

  getCollinearPoints(segment: Polyline, start: LatLngLiteral) {
    return this.leafletWrapper.getCollinearPoints(segment, start);
  }

  /**
   *  Triggered whenever an object (element) is clicked in the map
   *
   * @param event Emitted event (contains the element itself)
   */
  public onElementClick = (
    event: LeafletMouseEvent,
    mapLayers: Record<string, FeatureGroup<never>>,
    changedCallback: (objs) => void
  ): void => {
    // toggle selection: if it is selected then we want to deselect, and viceversa
    const objects = [];
    const element = event.target;
    const show = !element.options.selected;
    // if element forms part of total station
    if (element.options.layer === LayersEnum.TOTAL_STATION) {
      const tsElements = mapLayers[element.options.layer]?.getLayers() ?? [];

      // if there are more lines of total_station then, select this one and unselect the rest
      if (!element.options.selected && tsElements.length > 1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tsElements.forEach((segment: any) => {
          const res = this.changeElementVisibility(
            segment,
            segment.options.id === element.options.id && show
          );
          objects.push(res);
        });
      }
    } else {
      const res = this.changeElementVisibility(element, show);
      objects.push(res);
    }

    if (objects) {
      changedCallback(objects);
    }
  };

  /* Change given element visibility
   *
   * @param element Leaflet object to change
   * @param show True if the element is selected, False otherwise
   * @returns An object with basic obejct's information (to be send to parent)
   */
  public changeElementVisibility(element, show: boolean) {
    let color = this.constants.LAYER_PROPERTIES.other.color;
    if (element.options.layer in this.constants.LAYER_PROPERTIES) {
      let colorNotSelected =
        this.constants.LAYER_PROPERTIES[element.options.layer].colorNotSelected;
      if (element.options.printed) {
        colorNotSelected = this.constants.MAP_PRINTED_COLOR;
      } else {
        if (element.options.partiallyPrinted) {
          colorNotSelected = this.constants.MAP_PARTIALLY_PRINTED_COLOR;
        }
      }
      color = show
        ? this.constants.LAYER_PROPERTIES[element.options.layer].color
        : colorNotSelected;
    }
    let style = {};
    //if-else condition for basic paint of canvasLabel Texts
    if (element.options.labelStyle) {
      element.options.labelStyle.fillStyle = color;
      element.options.labelStyle.strokeStyle = color;
    } else {
      style = { color };
    }
    if (
      !element.options.layer ||
      element.options.layer === LayersEnum.TOTAL_STATION
    ) {
      color = this.constants.LAYER_PROPERTIES[LayersEnum.TOTAL_STATION].color;
      element.options.selected = show;
      const colorNotSelected =
        this.constants.LAYER_PROPERTIES[LayersEnum.TOTAL_STATION]
          .colorNotSelected;
      if (show) {
        if (element.options.labelStyle) {
          element.options.labelStyle.fillStyle = colorNotSelected; //color inside text
          element.options.labelStyle.strokeStyle = color; //color outside text
        }
        element.options.color = color;
        element.options.decorColor = color;
        style['color'] = color; // Color of the line
        style['decorBorder'] = colorNotSelected; // Border of decorator
        style['decorColor'] = color; // Color of decorator
        style['fillColor'] = colorNotSelected;
      } else {
        if (element.options.labelStyle) {
          element.options.labelStyle.fillStyle = colorNotSelected;
          element.options.labelStyle.strokeStyle = colorNotSelected;
        }
        style['color'] = colorNotSelected;
        style['decorBorder'] = 'transparent';
        style['decorColor'] = 'transparent';
        style['fillColor'] = colorNotSelected;
        element.options.color = colorNotSelected;
      }
    }
    element.options.selected = show;
    element.setStyle(style);

    return element.options;
  }

  public initMap(
    unit: UnitsValuesEnum,
    mapOptions?: MapExtendedOptions,
    mapContainerId?: string
  ): Map {
    const canvasLabel = new CanvasLabel({
      collisionFlg: true,
      scale: 2,
      tolerance: this.constants.MAP_TOUCH_TOLERANCE
    });
    const unitConversionFactor = this.constants.UNIT_CONVERSION_VALUE[unit];
    const options = {
      ...this.constants.MAP_DEFAULT_PROPERTIES,
      ...mapOptions,
      unitConversionFactor
    };
    //options.renderer = canvas({ tolerance: this.constants.MAP_TOUCH_TOLERANCE });
    options.renderer = canvasLabel;
    const newMap = map(
      mapContainerId ?? this.constants.MAP_CONTAINER_ID,
      options
    );
    this.leafletWrapper.addTouchHandler(newMap);
    this.leafletWrapper.overrideElements();

    return newMap;
  }
}
