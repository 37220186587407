import { FeedbackLoopingOperations } from 'src/components/SvgEditor/store/FeedbackLoopingOperations';
import { GoogleTagManager } from '../assets/static/googleTagManager';
import { INotificationService } from 'src/components/SvgEditor/types/INotificationService';

// Event data layer for Google Tag Manager
const eventDataLayer = (stack: string, { event, ...args }) => {
  GoogleTagManager.init(stack, {
    event: event,
    date: new Date().toUTCString(),
    ...args
  });
};

export default eventDataLayer;

export enum GTMEvents {
  OPEN_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_open',
  CLOSE_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_close',
  SAVE_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_save',
  ZOOM_IN_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_zoomIn',
  ZOOM_OUT_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_zoomOut',
  RECENTER_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_recenter',
  UNDO_ACTION_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_undo',
  DELETE_ELEMENTS_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_delete',
  JOIN_SEGMENTS_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_join_segments',
  START_DRAWING_LINES_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_draw_lines_start',
  END_DRAWING_LINES_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_draw_lines_end',
  START_DRAWING_ARC_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_draw_arc_start',
  DRAW_ARC_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_draw_arc',
  END_DRAWING_ARC_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_draw_arc_end',
  DRAW_LINE_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_draw_lines_line',
  SELECT_ELEMENT_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_select_element',
  DESELECT_ELEMENT_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_deselect_element',
  START_AREA_SELECTION_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_start_area_selection',
  END_AREA_SELECTION_FEEDBACK_LOOPING_PREVIEW = 'feedback_looping_end_area_selection',
  QUEUE_LIST_TAB_CHANGE = 'queue_list_tab_change',
  QUEUE_LIST_SORT_BY = 'queue_list_sort_by',
  QUEUE_LIST_PAGE_CHANGE = 'queue_list_page_change',
  QUEUE_LIST_FILTER_BY_DATE = 'queue_list_filter_by_date'
}
export class NotificationService implements INotificationService {
  private jobID: string;
  private stack: string;
  constructor(jobID: string, stack: string) {
    this.jobID = jobID;
    this.stack = stack;
  }
  notifySegmentJoin = (
    selectedElements: string[],
    result: string,
    svgId: string
  ) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.JOIN_SEGMENTS_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      selectedElements,
      editResult: result,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyRemoveElements = (selectedElements: string[], svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.DELETE_ELEMENTS_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      selectedElements,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyZoomIn = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.ZOOM_IN_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyZoomOut = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.ZOOM_OUT_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyRecenter = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.RECENTER_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyUndoAction = (svgId: string, action: FeedbackLoopingOperations) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.UNDO_ACTION_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId,
      action: action.toString()
    });
  };
  notifyClosePreview = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.CLOSE_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyOpenPreview = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.OPEN_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifySavePreview = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.SAVE_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifySelectElement = (elementId: string, svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.SELECT_ELEMENT_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId,
      selectedElements: [elementId]
    });
  };
  notifyDeselectElement = (elementId: string, svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.DESELECT_ELEMENT_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId,
      selectedElements: [elementId]
    });
  };
  notifyEnableDrawLine = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.START_DRAWING_LINES_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyDisableDrawLine = (svgId: string, trigger: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.END_DRAWING_LINES_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId,
      trigger
    });
  };
  notifyEnableDrawArc = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.START_DRAWING_ARC_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyDrawArc = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.DRAW_ARC_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyDisableDrawArc = (svgId: string, trigger: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.END_DRAWING_ARC_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId,
      trigger
    });
  };
  notifyDrawLine = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.DRAW_LINE_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyStartAreaSelection = (svgId: string) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.START_AREA_SELECTION_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId
    });
  };
  notifyEndAreaSelection = (svgId: string, selectedElements: string[]) => {
    eventDataLayer(this.stack, {
      event: GTMEvents.END_AREA_SELECTION_FEEDBACK_LOOPING_PREVIEW,
      jobID: this.jobID,
      feedbackLoopingSessionID: svgId,
      selectedElements
    });
  };
}
